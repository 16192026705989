import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'url-search-params-polyfill';
import 'pepjs';
import 'raf/polyfill';

/** IE9 and IE10 requires the following polyfill */
( function polyfill() {
  const setProtoOf = ( obj, proto ) => {
    obj.__proto__ = proto;
    return obj;
  };

  const mixinProperties = ( obj, proto ) => {
    for ( const prop in proto ) {
      if ( !obj.hasOwnProperty( prop )) {
        obj[prop] = proto[prop];
      }
    }
    return obj;
  };

  Object.setPrototypeOf =
    Object.setPrototypeOf || ({ __proto__: []} instanceof Array ? setProtoOf : mixinProperties );
})();

/** A human being can't live without capitalize */
// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function capitalize() {
  return this.replace( /\w\S*/g, ( text ) => text.charAt( 0 ).toUpperCase() + text.slice( 1 ));
};

/** Only partial support, use with caution and verify it works for your usecase */
// eslint-disable-next-line no-extend-native
String.prototype.kebabCase = function string() {
  return this.replace( /([a-z])([A-Z])/g, '$1-$2' )
    .replace( /[\s_]+/g, '-' )
    .replace( /[\s']+/g, '' )
    .toLowerCase();
};
